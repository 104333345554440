import React from 'react';

function RegisterScreen() {

    return(
        <div>
            <h1>Register Screen</h1>
        </div>
    )
}

export default RegisterScreen;